import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import Certificate from "../images/group-2.png";
import Dotimg from "../images/Rectangle.png";
import { Link } from "gatsby";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";
import moment from "moment";
require("sweetalert/dist/sweetalert.css");
require("../css/payment.css");

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      props: this.props.location.state != null ? this.props : []
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <HeaderLight pathname="/paymentconfirmation" />

        <div className="container NC-vision1 text-center">
          <div className="row mar-tpr-tp">
            <div className="col-sm-12 col-md-12 col-xs-12 padTop15 flt-lft">
              <div className="d-flex justify-content-center bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  {" "}
                  Delivery{" "}
                  <span className="circle-rectang">
                    {" "}
                    <img src={Dotimg} />{" "}
                  </span>
                </div>
                <div className="p-2 bd-highlight">
                  Payment{" "}
                  <span className="circle-rectang">
                    {" "}
                    <img src={Dotimg} />
                  </span>
                </div>
                <div className="p-2 bd-highlight payTabs payTabActive underline-fro-txt">
                  Confirmation
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <img
            className="certificateImg"
            src="https://res.cloudinary.com/djoztpm50/image/upload/v1556885938/Native%20Circle/group-2-min.png"
            alt="payment successful"
          />
          <p className="bold-text"> Thank you for your order!</p>
          <p className="small-text-bold-nw">
            {" "}
            Your purchase is confirmed and below are the transaction details for
            your reference.{" "}
          </p>
          <div className="mx-9 noMx-9Media320">
            {" "}
            <div className="d-flex justify-content-center bd-highlight1 mb-0 ">
              <div className="px-2 py-1 bd-highlight2">Order ID</div>
              <div className="px-2 py-1 bd-highlight3">
                {this.props.location.state == undefined ||
                this.props.location.state == null
                  ? ""
                  : this.props.location.state.razorpay_payment_id}
                {/* {this.props.location.state.key == undefined
                  ? ""
                  : this.props.location.state.key} */}
              </div>
            </div>
            <div className="d-flex justify-content-center bd-highlight1 mb-0">
              <div className="px-2 py-1 bd-highlight2">Quantity</div>
              <div className="px-2 py-1 bd-highlight3">
                {this.props.location.state == undefined ||
                this.props.location.state == null
                  ? ""
                  : this.props.location.state.props.totalQuantity}
              </div>
            </div>
            <div className="d-flex justify-content-center bd-highlight1 mb-0 ">
              <div className="px-2 py-1 bd-highlight2">Order value</div>
              <div className="px-2 py-1 bd-highlight3">
                &#8377;
                {this.props.location.state == undefined ||
                this.props.location.state == null
                  ? ""
                  : this.props.location.state.props.amountPayable}
              </div>
            </div>
            <div className="d-flex justify-content-center bd-highlight1 mb-0 ">
              <div className="px-2 py-1 bd-highlight2">Order Date & time</div>
              <div className="px-2 py-1 bd-highlight3">
                {/* 1 Oct 2019 1:23 PM */}
                {moment(new Date()).format("D MMM YYYY h:mm a")}
              </div>
            </div>
          </div>
          <p className="small-text my-5">
            {" "}
            Please feel free to write to{" "}
            <span className="supprt"> support@nativecircle.com </span> if you
            have any querries or assistance needed.{" "}
          </p>

          <Link to="/products" className="shop-mr-but mt-5">
            Shop more <span className="arws-rgts-qt">→</span>
          </Link>
        </div>

        {/* </div>
                    </div> */}
        <div className="NC-paddingTop-50">
          <Footer />
        </div>
      </div>
    );
  }
}
export default PaymentConfirmation;
